import { type ComponentPropsWithoutRef, useState } from "react";
import { RiCalendarCheckLine } from "react-icons/ri";
import type { Product } from "~types/api/product.types";

import { useRootMatchData } from "~/root";
import { getCharacteristicValue } from "~/services/characteristics";
import { cn } from "~/utils/classnames";

import "./Stock.css";

type ProductStock = Product["stocks"][0];

const getWeek = (date?: string): number => {
  // Get week number from date
  const dateToConvert = date ? new Date(date) : new Date();
  const onejan = new Date(dateToConvert.getFullYear(), 0, 1);
  const millisecsInDay = 86400000;
  return Math.ceil(
    ((dateToConvert.getTime() - onejan.getTime()) / millisecsInDay + onejan.getDay() + 1) / 7
  );
};

const getFormatedStocks = (stock: ProductStock) => {
  // Calculate max number of weeks displayed
  const maxStockWeeks = Object.keys(stock).filter((key) => key.startsWith("stock")).length;
  // Create an array of stocks with their week number
  const formatedStocks = Array(maxStockWeeks)
    .fill(null)
    .map((_, index) => {
      const stockKey = `stock${index}` as keyof ProductStock;
      const dateKey = `date${index}` as keyof ProductStock;
      return {
        stock: Number(stock[stockKey]),
        week: Number(getWeek(stock[dateKey] as string)),
      };
    });

  const maxStock = formatedStocks.reduce(
    (max, current) => (current.stock > max ? current.stock : max),
    0
  );

  return { formatedStocks, maxStock };
};

const getStockLabels = ({
  product,
  stock,
  formatedStocks,
  maxDisplayedStock,
  lite,
}: {
  product: Product;
  stock: ProductStock;
  formatedStocks: ReturnType<typeof getFormatedStocks>["formatedStocks"];
  maxDisplayedStock: number;
  lite: boolean;
}) => {
  const stockCount = Number(stock.currentStock);
  const itemLabel =
    getCharacteristicValue(product, "cable-ac") === "1"
      ? "km"
      : `produit${stockCount > 1 ? "s" : ""}`;

  switch (stock.etatStock) {
    case 1:
      if (lite) {
        return {
          stock: `${stockCount > maxDisplayedStock ? `+${maxDisplayedStock}` : stockCount} en stock`,
          availability: `Dispo s${formatedStocks[0]?.week}`,
        };
      }
      return {
        stock: `${stockCount > maxDisplayedStock ? `+${maxDisplayedStock}` : stockCount} ${itemLabel} en stock`,
        availability: `Dispo s${formatedStocks[0]?.week}`,
      };
    case 2:
      if (lite) {
        return {
          stock: `${stockCount > maxDisplayedStock ? `+${maxDisplayedStock}` : stockCount} en stock`,
          availability: `Dispo s${formatedStocks[0]?.week}`,
        };
      }
      return {
        stock: `${stockCount > maxDisplayedStock ? `+${maxDisplayedStock}` : stockCount} ${itemLabel} en stock`,
        availability: `Dispo s${formatedStocks[0]?.week}`,
      };
    case 3:
      return {
        stock: `Bientôt disponible (s${formatedStocks[0]?.week})`,
        availability: `Prochaine dispo s${formatedStocks[0]?.week}`,
      };
    case 4:
      return { stock: "Sur commande", availability: null };
    case 5:
      return { stock: "Plus disponible", availability: null };
    default:
      return { stock: "", availability: null };
  }
};

interface StockProps extends ComponentPropsWithoutRef<"div"> {
  product: Product;
  stock?: Product["stocks"][0] | null | undefined;
  lite?: boolean;
}

export const Stock = ({ product, stock, lite = false, className, ...props }: StockProps) => {
  const rootData = useRootMatchData();
  const maxInverterStock = rootData?.settings?.find((s) => s.code === "QteMaxOnduleurs")?.value;
  const maxDisplayedStock =
    product?.categories?.[0]?.slug === "onduleurs-solaires" ? Number(maxInverterStock) : 1000;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  if (!stock) return null;

  // Formated stocks with week number
  const { formatedStocks, maxStock } = getFormatedStocks(stock);

  // Get stock label and availability label
  const stockLabels = getStockLabels({ product, stock, formatedStocks, maxDisplayedStock, lite });

  const variant = (() => {
    switch (stock.etatStock) {
      case 1:
        return "success";
      case 2:
        return "success";
      case 3:
        return "warning";
      case 4:
        return "warning";
      default:
        return "danger";
    }
  })();

  return (
    <div
      className={cn(
        "Stock",
        lite && "Stock--lite",
        isPopoverOpen && "Stock--popoverOpen",
        className
      )}
      {...props}
    >
      <button
        onMouseOver={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
        onFocus={() => setIsPopoverOpen(true)}
        onBlur={() => setIsPopoverOpen(false)}
        className={cn(`Stock-label`, `Stock-label--${variant}`)}
      >
        {stockLabels.stock}
      </button>
      {!lite && formatedStocks && stock.etatStock !== 4 && stockLabels.availability ? (
        <button
          className="Stock-availability"
          onMouseOver={() => setIsPopoverOpen(true)}
          onMouseLeave={() => setIsPopoverOpen(false)}
          onFocus={() => setIsPopoverOpen(true)}
          onBlur={() => setIsPopoverOpen(false)}
        >
          Voir les dispos
          <RiCalendarCheckLine
            className={cn(
              "size-4",
              variant === "success"
                ? "text-success-700"
                : variant === "warning"
                  ? "text-warning-600"
                  : variant === "danger"
                    ? "text-danger-700"
                    : null
            )}
          />
        </button>
      ) : null}
      {!lite && stock.etatStock !== 4 ? (
        <div className="Stock-popover">
          <p className="Stock-popover-title">Stocks des prochaines semaines :</p>
          <div className="Stock-popover-arrow" />
          <div className="Stock-popover-graph">
            {formatedStocks && stock.etatStock !== 4
              ? formatedStocks.map((stock, index) => (
                  <div
                    key={`${product.id}-${stock.week}-${index}`}
                    className="Stock-popover-graph-col"
                  >
                    <span className="Stock-popover-graph-col-label">
                      {stock.stock > maxDisplayedStock ? `+${maxDisplayedStock}` : stock.stock}
                    </span>
                    <div className="Stock-popover-graph-col-bar">
                      <div
                        className="Stock-popover-graph-col-bar-progress"
                        style={{
                          height: `${
                            ((stock.stock >= maxDisplayedStock ? maxDisplayedStock : stock.stock) /
                              ((maxStock >= maxDisplayedStock ? maxDisplayedStock : maxStock) +
                                50)) *
                            100
                          }%`,
                        }}
                      />
                    </div>
                    <div className="Stock-popover-graph-col-separator" />
                    <span className="Stock-popover-graph-col-label">s{stock.week}</span>
                  </div>
                ))
              : null}
          </div>
          <p className="Stock-popover-weekInfo">Nous sommes semaine {getWeek()}</p>
        </div>
      ) : null}
    </div>
  );
};
